import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgParentNotListening from "../images/parent-not-listening.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Why parents don't want to use your school bus" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">Why parents don't want to use your school bus</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">Why parents don't want to use your school bus</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>You might not want to hear it, but some parents don't want to use your school bus - and the reason isn't just the cost!</p>
                    <p>ShuttleID is used on over 8 million school bus passenger journeys each year, helping transport operators add value to parents. Analysing feedback from parents and operators, we've documented common parent objections to letting their child use the school bus. Whilst transport cost will always be a factor, you could be overlooking other issues that are putting off some parents from using your school bus.</p>
                    <p>By handling these objections in your marketing materials and when speaking directly with parents, it can help you retain existing customers and prevent competitors gaining traction. Additionally, it can increase revenue by persuading new customers to ditch the car and that your school bus is a no-brainer.</p>

                    <p><img src={imgParentNotListening} alt="" /></p>
                  </div>
                  <div className='article-inner'>
                    <h2>Common parent objections to using your school&nbsp;bus</h2>

                    <h3>1. Safety</h3>
                    <h4>Accident prevention</h4>
                    <p>How will a parent know their child will be safe using your school bus service? School bus accidents seem to be in <a className="link" href="https://www.walesonline.co.uk/news/wales-news/school-bus-crashed-electricity-pole-25962975" target="_blank">the</a> <a className="link" href="https://www.bbc.co.uk/news/uk-england-essex-63202958" target="_blank">headlines</a> <a className="link" href="https://www.somersetlive.co.uk/news/somerset-news/frome-school-bus-crash-approximately-7877470" target="_blank">often</a>. </p>
                    <ol>
                      <li>What steps have you taken to help prevent accidents in future?</li>
                      <li>Are your vehicles well maintained and inspected often?</li>
                      <li>Are drivers CRB-checked, trained properly and well-rested?</li>
                      <li>Do you have evidence to back this claim up?</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>Drivers are less distracted as the job of checking passes is largely removed. Passengers instead wave their ticket or phone at a fixed mobile device to board. This allows drivers to focus on the road and wellbeing of passengers. </p>

                    <h4>Accident handling</h4>
                    <p>Even with the best drivers and preventative measures, your school bus ultimately shares a road with other drivers that may not take safety as seriously.</p>
                    <ol>
                      <li>What do you have in place that helps protect children in the event of an accident (seatbelts, first aid kits/trained drivers, working fire extinguishers, etc)?</li>
                      <li>How would you know exactly who is on board that journey?</li>
                      <li>How would you contact affected parents?</li>
                      <li>How quickly would you be able to do it?</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>ShuttleID gives operators the ability to see exactly who has boarded their school bus. Not just who <em>should</em> be travelling but who actually <em>is</em> travelling.The passenger list is available to management and the office in real-time which can double up as a register in the event of an incident. The service alerts feature lets operators inform affected parents by SMS in seconds. Live vehicle tracking via the system can help pinpoint to both operators and parents the location of an incident.</p>

                    <h4>Bullying</h4>
                    <p>The school bus is often seen as an extension of the classroom or playground. Unfortunately this can mean the safety of the vehicle and road isn’t the only issue, but safety from the other passengers too. Parents need assurance that bullying isn’t tolerated in school and equally not <a className="link" href="https://www.dailyrecord.co.uk/news/scottish-news/teen-girl-beaten-school-bus-29064250" target="_blank">on the school bus</a> either.</p>
                    <ol>
                      <li>Do you have a bus behaviour policy in place?</li>
                      <li>How do you enforce it?</li>
                      <li>How can you ensure a bully cannot fraudulently board your school bus?</li>
                      <li>How can a victim of bullying identify the culprit post journey?</li>
                      <li>What if a bully steals their bus fare?</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>ShuttleID is an effective anti-bullying tool. We have heard of instances where bullies have boarded vehicles purposely to target their victim, even if they don’t have a valid bus pass. ShuttleID helps prevent this scenario completely by ensuring that every pass is valid, performing over 9 checks of validity in under one second. As the check is performed by a tablet device, it makes the job easier for your driver to get control of the situation. The risk of bullies stealing a victim's bus fare is no longer an issue as the system is completely cashless.</p>

                    <h4>Trust</h4>
                    <p>How can parents trust the safety of your school bus?</p>
                    <ol>
                      <li>Is there some form of social proof that backs it up?</li>
                      <li>Are you a member of a trade body that gives you authority?</li>
                      <li>Have you been independently verified as being safe through a scheme such as <a className="link" href="https://busk-uk.co.uk/" target="_blank">BUSK</a>?</li>
                      <li>Do your online reviews instil confidence or will they scare parents off?</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>We often hear from operators it can be difficult to answer every call and email instantly. Customer expectations continue to grow and can be unforgiving, which can leave a lasting impact on your online reviews. Being able to lean on the features of ShuttleID (live vehicle tracking, SMS service alerts, instant ticket delivery, 24/7 availability) is a big advantage. It takes pressure off your customer-facing team, restores faith in your service and makes your school bus a no-brainer.</p>

                    <h3>2. Distance</h3>
                    <h4>Too close</h4>
                    <p>If the school is located close enough, parents may prefer to drive the child to avoid the time and inconvenience of taking the bus.</p>
                    <ol>
                      <li>What processes have you put in place to make purchasing or issuing passes effortless?</li>
                      <li>Are you still causing parents to scramble for correct change each day?</li>
                      <li>How do you ensure that schoolchildren aren't needlessly waiting at the bus stop in the event the bus is running late or even cancelled?</li>
                      <li>Have you explained that even on short journeys your school bus helps take cars off the road and improves journey time? Your school bus means local roads are less congested, it’s better for the environment and the stress of parking for parents is avoided.</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>ShuttleID gives convenience to parents buying and receiving school bus passes. Passes can be used on a phone or printed out instantly. No hunting for cash and no fuss. Live vehicle tracking and service alerts ensure parents are informed at all times to avoid their children spending any unnecessary time waiting at the bus stop.</p>

                    <h4>Too far</h4>
                    <p>When the school is far away, this may turn into a further safety concern.  Having the benefit of working and speaking with lots of transport operators, it’s frighteningly common for a child to be reported missing, and panicked parents need to know if they made it onto the bus.</p>
                    <ol>
                      <li>How can parents be reassured their child will get to their school and back every single day, when they have so far to travel?</li>
                      <li>How well equipped are you to handle this?</li>
                      <li>Can you reliably tell if a child boarded your bus that day and time?</li>
                      <li>When every second counts, how quickly can you get that answer to parents with accuracy?</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>ShuttleID reassures parents that they’ll always know if their child made it onto the bus, as the data is captured and available quickly. On longer journeys, live vehicle tracking can help reassure a parent when the bus is late whether it’s just around the corner or whether the bus might take a bit longer than normal. Always being able to keep parents informed is now an expectation.</p>

                    <h3>3. Scheduling conflicts</h3>
                    <p>The existing school bus schedule may not align with the child’s after-school activities or parent's work schedule. By publishing your timetable you may immediately alienate and put off customers that would otherwise want to use your regular scheduled service for the majority of time.</p>
                    <ol>
                      <li>Do you have a way of offering transport for after-school clubs?</li>
                      <li>Is a late bus something that can be offered (even subsidised by the school)?</li>
                      <li>Are you able to offer bus passes with varying flexibility to help capture this extra demand?</li>
                    </ol>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>ShuttleID can enable operators to offer extra flexibility. Either by allowing tickets to also be used on a separate late service, or by offering more flexibility in travel patterns (AM/PM only travel, Mon-Thurs travel, etc). With manual systems this would be impossible to accurately enforce and manage, but with a system like ShuttleID, validation of tickets is automatic.</p>
                    <h3>4. Preference for personal transport</h3>
                    <img src={imgMobileTicket} alt="Mobile ticket for school transport" />
                    <p>Some parents may simply prefer to drive their child to spend more time with them. Have you helped parents understand that your school bus is more than just getting from A to B, but an experience that helps their children develop social skills and create friendships? Feedback we hear from operators is that schoolchildren often prefer to take the bus over parents driving them in, if it means they get to sit and chat with their friends. Make sure you’re informing parents on what their children are missing out on!</p>

                    <h2>Summary</h2>
                    <p>We hope reading this article has helped stimulate some discussion and ideas in your office. Whilst not exhaustive, the above is designed to help you identify what you are already doing well and what else could be a quick win that makes a big impact to your business. Operators often take the value they already provide for granted by simply not promoting it clearly to customers. Remember, even if two operators offer near identical service, the one that is able to explain and sell their value better is going to win every time.</p>
                    <p>The next time you revisit your website, brochure or social media strategy, take stock of why a parent may hesitate to use your school bus and thoroughly put their potential objections to bed.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>Can ShuttleID help me?</span>Yes! The benefits of ShuttleID not only makes running your school bus easier to manage but importantly it makes your customers happy. Our technology is designed to be simple, affordable and keeps you in full control. Get in touch today on 0333 344 9868 for more information or book your <Link style={{ fontWeight: 'bold', color: 'white' }} to="/book-demo/">free online demo</Link>.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID; the affordable, digital bus pass and ticketing system that keeps you in control.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home-to-school first hand, leading to the launch of ShuttleID in 2019.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage